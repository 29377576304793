export const GoogleAdsConversionAccountId = 'AW-10834483973';

const mkConv = (conversionId: string) =>
  `${GoogleAdsConversionAccountId}/${conversionId}`;

export const GoogleAdsConvClickInstallGoogleSheetsAddon = mkConv(
  'mpv8CNWUho4DEIW2pK4o'
);
export const GoogleAdsConvChoosePlan = mkConv('mzC9CN_u9s4YEIW2pK4o');
export const GoogleAdsConvSubscribe = mkConv('t17fCPD6no4DEIW2pK4o');
export const GoogleAdsConvClickInstallConnector = mkConv(
  'wo8zCPOPvskDEIW2pK4o'
);
