import amplitude from 'amplitude-js';
import { getApp, WebAppName } from './env';
import { event as ga4Event } from '../components/root/GoogleAnalytics4';
import { trackAmplitudeEvent } from './amplitude.client';
import { identifySentry } from './sentry.client';

type Event = {
  category: string;
  name: string;
};
export { Events } from './events';

const mkProperties = (origProperties: {
  [key: string]: unknown;
}): { [key: string]: unknown } => {
  return { ...origProperties, app: WebAppName, webApp: getApp() };
};

export const trackEvent = (
  event: Event,
  origProperties: { [key: string]: unknown } = {}
) => {
  const properties = mkProperties(origProperties);
  const label =
    'label' in properties ? (properties.label as string) : undefined;

  const generatedlabel =
    Object.keys(properties).length > 0 ? JSON.stringify(properties) : undefined;

  return Promise.all([
    ga4Event({
      action: `${event.category}.${event.name}`,
      event_label: label || generatedlabel,
    }),
    trackAmplitudeEvent(`${event.category}.${event.name}`, properties),
  ]);
};

export const trackError = (
  action: string,
  err: Error | string,
  origProperties: { [key: string]: unknown } = {}
) => {
  const properties = mkProperties(origProperties);
  const message = typeof err === 'string' ? err : err.message;

  console.error(err);
  return Promise.all([
    ga4Event({ action: 'error', event_label: message }),
    trackAmplitudeEvent('error', {
      ...properties,
      action,
      message,
    }),
  ]);
};

export type IdentifyUser = {
  id: string;
  email?: string;
};

export const identify = (user: IdentifyUser | undefined) => {
  amplitude.setUserId(user?.id || null);
  identifySentry(user);
};
